import React, { useState, useEffect } from 'react';
import './ReservationTable.css';

const ReservationsTable = () => {
  const [reservations, setReservations] = useState([]);
  const [products, setProducts] = useState([]); // Pour stocker les produits
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Récupération des réservations et des produits
    const fetchReservationsAndProducts = async () => {
      try {
        // Fetch des réservations
        const reservationsResponse = await fetch('https://gourmet-2.onrender.com/api/Mes-reservations');
        if (!reservationsResponse.ok) {
          throw new Error('Erreur lors de la récupération des réservations');
        }
        const reservationsData = await reservationsResponse.json();

        // Fetch des produits
        const productsResponse = await fetch('https://gourmet-2.onrender.com/api/produits');
        if (!productsResponse.ok) {
          throw new Error('Erreur lors de la récupération des produits');
        }
        const productsData = await productsResponse.json();

        // Stocker les données
        setReservations(reservationsData);
        setProducts(productsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReservationsAndProducts();
  }, []);

  if (loading) return <p>Chargement des réservations...</p>;
  if (error) return <p>Erreur : {error}</p>;

  // Fonction pour obtenir le nom d'un produit à partir de son ID
  const getProductName = (productId) => {
    const product = products.find((p) => p.id === productId);
    return product ? product.name : `Produit inconnu (ID: ${productId})`;
  };

  return (
    <div>
      <h2>Liste des Réservations</h2>
      <table>
        <thead>
          <tr>
            <th>Téléphone</th>
            <th>Date de réservation</th>
            <th>Produits</th>
          </tr>
        </thead>
        <tbody>
          {reservations.map((reservation, index) => (
            <tr key={index}>
              <td>{reservation.phone}</td>
              <td>{reservation.reservation_date}</td>
              <td>
                {/* Afficher les noms des produits avec leurs quantités */}
                {reservation.produits
                  .split(', ')
                  .map((prod) => {
                    const [productId, quantity] = prod.match(/\d+/g);
                    return (
                      <div key={productId}>
                        {getProductName(parseInt(productId))} (x{quantity})
                      </div>
                    );
                  })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReservationsTable;
